import React, { useState, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./ReceiptBuilder.css";
import logo from "../logo-vpvv.png";

const ReceiptBuilder = () => {
  const receiptRef = useRef();

  const [receiptDetails, setReceiptDetails] = useState({
    firstName: "",
    companyName: "",
    paymentType: "Cheque",
    paymentNo: "",
    amount: "",
    date: new Date().toISOString().split("T")[0],
    receiptNo: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReceiptDetails({ ...receiptDetails, [name]: value });
  };

  const handleDownload = async () => {
    const element = receiptRef.current;

    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
    pdf.save("receipt.pdf");
  };

  return (
    <div className="receipt-builder">
      <h1>Dynamic Receipt Builder</h1>

      {/* Form Section */}
      <div className="form-section">
        <h2>Receipt Details</h2>
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={receiptDetails.firstName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="companyName"
          placeholder="Company Name"
          value={receiptDetails.companyName}
          onChange={handleInputChange}
        />
        <select
          name="paymentType"
          value={receiptDetails.paymentType}
          onChange={handleInputChange}
        >
          <option value="Cheque">Cheque</option>
          <option value="RTGS">RTGS</option>
          <option value="NEFT">NEFT</option>
        </select>
        <input
          type="text"
          name="paymentNo"
          placeholder={`Enter ${receiptDetails.paymentType} No.`}
          value={receiptDetails.paymentNo}
          onChange={handleInputChange}
        />
        <input
          type="number"
          name="amount"
          placeholder="Amount"
          value={receiptDetails.amount}
          onChange={handleInputChange}
        />
        <input
          type="date"
          name="date"
          value={receiptDetails.date}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="receiptNo"
          placeholder="Receipt No."
          value={receiptDetails.receiptNo}
          onChange={handleInputChange}
        />
      </div>

      {/* Receipt Preview */}
      <div ref={receiptRef} className="receipt">
        <div className="header">
          <div className="logo">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "7rem" }}
              className="logo-image"
            />
          </div>
          <div className="receipt-title">
            <h1>Receipt</h1>
          </div>
        </div>
        <table className="receipt-table">
          <tbody>
            <tr>
              <td>
                <strong>Receipt No:</strong>
              </td>
              <td>{receiptDetails.receiptNo}</td>
            </tr>
            <tr>
              <td>
                <strong>Date:</strong>
              </td>
              <td>{receiptDetails.date}</td>
            </tr>
            <tr>
              <td>
                <strong>First Name:</strong>
              </td>
              <td>{receiptDetails.firstName}</td>
            </tr>
            <tr>
              <td>
                <strong>Company Name:</strong>
              </td>
              <td>{receiptDetails.companyName}</td>
            </tr>
            <tr>
              <td>
                <strong>{receiptDetails.paymentType} No:</strong>
              </td>
              <td>{receiptDetails.paymentNo}</td>
            </tr>
            <tr>
              <td>
                <strong>Amount:</strong>
              </td>
              <td>${receiptDetails.amount}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Download Button */}
      <button className="download-btn" onClick={handleDownload}>
        Download Receipt
      </button>
    </div>
  );
};

export default ReceiptBuilder;
