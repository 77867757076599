import React, { useEffect, useState } from "react";
import {
  RiAddCircleFill,
  RiDashboardFill,
  RiEyeFill,
  RiUser3Fill,
  RiCustomerService2Fill,
} from "react-icons/ri";
import { FaQuestionCircle } from "react-icons/fa";
import { IoNewspaperSharp } from "react-icons/io5";
import { IoNotifications } from "react-icons/io5";
import { MdEventAvailable } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { IoDocument } from "react-icons/io5";
import { IoMdCreate } from "react-icons/io";
import { MdHistory } from "react-icons/md";
import { CiBank } from "react-icons/ci";
import { LuActivitySquare } from "react-icons/lu";
import { MdViewModule } from "react-icons/md";
import "./Sidebar.css";
import logo from "../logo-vpvv.png";
import { useDispatch } from "react-redux";
import { getmyadmin } from "../../redux/action/companyaction";
import { BsBank2 } from "react-icons/bs";
import { FaFistRaised } from "react-icons/fa";
import { MdCoPresent } from "react-icons/md";



const Sidebar = () => {
  const dispatch = useDispatch();
  const [admin, setAdmin] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const fetchadmin = async () => {
      try {
        const data = await dispatch(getmyadmin());
        setAdmin(data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchadmin();
  }, [dispatch]);
  return (
    <div className="sidebar">
      <img style={{ width: "7rem" }} src={logo} alt="Logo" />
      <LinkButton
        Icon={RiDashboardFill}
        text="Dashboard"
        url="profile"
        active={location.pathname === "/admin/profile"}
      />
      <LinkButton
        Icon={MdCoPresent}
        text="Attendance"
        url="attendance"
        active={location.pathname === "/admin/attendance"}
      />
      <LinkButton
        Icon={RiDashboardFill}
        text="View Nda"
        url="Nda"
        active={location.pathname === "/admin/Nda"}
      />
      <LinkButton
        Icon={RiAddCircleFill}
        text="Register Client"
        url="register"
        active={location.pathname === "/admin/register"}
      />
      <LinkButton
        Icon={RiAddCircleFill}
        text="Register Individual Client"
        url="registerIndividual"
        active={location.pathname === "/admin/registerIndividual"}
      />
      <LinkButton
        Icon={IoDocument}
        text="Add NDA Documents"
        url="registerNDA"
        active={location.pathname === "/admin/registerNDA"}
      />
      <LinkButton
        Icon={IoMdCreate}
        text="Charity"
        url="createcharity"
        active={location.pathname === "/admin/createcharity"}
      />
      <LinkButton
        Icon={IoNewspaperSharp}
        text="News"
        url="news"
        active={location.pathname === "/admin/news"}
      />
      <LinkButton
        Icon={IoNotifications}
        text="Updates"
        url="notification"
        active={location.pathname === "/admin/notification"}
      />
      <LinkButton
        Icon={MdEventAvailable}
        text="Event"
        url="events"
        active={location.pathname === "/admin/events"}
      />
      <LinkButton
        Icon={RiCustomerService2Fill}
        text="Help"
        url="helpdesk"
        active={location.pathname === "/admin/helpdesk"}
      />
      {admin?.role === "superadmin" && (
        <LinkButton
          Icon={RiUser3Fill}
          text="Company list"
          url="users"
          active={location.pathname === "/admin/users"}
        />
      )}
      {admin?.role === "superadmin" && (
        <LinkButton
          Icon={RiUser3Fill}
          text="Individual list"
          url="individullist"
          active={location.pathname === "/admin/individullist"}
        />
      )}
      {
        admin?.role === "superadmin" && (
          <LinkButton
          Icon={MdHistory}
          text="LoginHistory"
          url="loginhistory"
          active={location.pathname === "/admin/loginhistory"}
        />
        )
     }
      {
        admin?.role === "superadmin" && (
          <LinkButton
          Icon={RiUser3Fill}
          text="Staff list"
          url="stafflist"
          active={location.pathname === "/admin/stafflist"}
        />
        )
     }
      {
        admin?.role === "superadmin" && (
          <LinkButton
          Icon={LuActivitySquare}
          text="Staff Activity Log"
          url="activitylog"
          active={location.pathname === "/admin/activitylog"}
        />
        )
     }
      {
        admin?.role === "superadmin" && (
          <LinkButton
          Icon={BsBank2}
          text="All BAnkforms"
          url="bankforms"
          active={location.pathname === "/admin/bankforms"}
        />
        )
     }
      {
        admin?.role === "superadmin" && (
          <LinkButton
          Icon={FaFistRaised}
          text="All Training Form"
          url="alltrainingform"
          active={location.pathname === "/admin/alltrainingform"}
        />
        )
     }
      {
        admin?.role === "superadmin" && (
          <LinkButton
          Icon={MdViewModule}
          text="Create Training Module"
          url="createTrainingmodule"
          active={location.pathname === "/admin/createTrainingmodule"}
        />
        )
     }
      {
        admin?.role === "superadmin" && (
          <LinkButton
          Icon={MdViewModule}
          text="Excel bank form"
          url="excelbankform"
          active={location.pathname === "/admin/excelbankform"}
        />
        )
     }
      {
        admin?.role === "superadmin" && (
          <LinkButton
          Icon={MdViewModule}
          text="Excel Training form"
          url="excelTrainingform"
          active={location.pathname === "/admin/excelTrainingform"}
        />
        )
     }
    </div>
  );
};

export default Sidebar;

const LinkButton = ({ url, Icon, text, active }) => {
  return (
    <Link
      to={`/admin/${url}`}
      className={`link-button ${active ? "active" : ""}`}
    >
      <Icon className="icon" /> {/* Render the Icon as a component */}
      <span>{text}</span>
    </Link>
  );
};
