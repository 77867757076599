import axios from 'axios';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelTrainingForm = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const { data } = await axios.get("http://localhost:4000/api/v1/alltraining");
        setGuests(data.training || []); // Ensure the data structure matches
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchGuests();
  }, []);

  const filteredGuests = guests.filter((guest) =>
    guest.Company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = () => {
    const dataToExport = filteredGuests.map((guest) => ({
      "Company Name": guest.Company.name,
      "Training Form 1": guest.document[0]?.url || "N/A",
      "Training Form 2": guest.document[1]?.url || "N/A",
      "Document 1": guest.passport[0]?.url || "N/A",
      "Document 2": guest.passport[1]?.url || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Training Data");

    // Generate Excel file
    XLSX.writeFile(workbook, "TrainingData.xlsx");
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h2>Training Data</h2>

      {/* Search Bar */}
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search by Company Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            padding: "10px",
            width: "100%",
            boxSizing: "border-box",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </div>

      {/* Download Button */}
      <div style={{ marginBottom: "20px" }}>
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Download Excel
        </button>
      </div>

      {/* Table for Displaying Data */}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
          textAlign: "left",
        }}
      >
        <thead>
          <tr>
            <th style={{ borderBottom: "2px solid #ddd", padding: "10px" }}>Company Name</th>
            <th style={{ borderBottom: "2px solid #ddd", padding: "10px" }}>Training Form 1</th>
            <th style={{ borderBottom: "2px solid #ddd", padding: "10px" }}>Training Form 2</th>
            <th style={{ borderBottom: "2px solid #ddd", padding: "10px" }}>Document 1</th>
            <th style={{ borderBottom: "2px solid #ddd", padding: "10px" }}>Document 2</th>
          </tr>
        </thead>
        <tbody>
          {filteredGuests.map((guest, index) => (
            <tr key={index}>
              <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                {guest.Company.name}
              </td>
              <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                {guest.document[0]?.url ? (
                  <a href={guest.document[0].url} target="_blank" rel="noopener noreferrer">
                    View Training Form 1
                  </a>
                ) : (
                  "N/A"
                )}
              </td>
              <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                {guest.document[1]?.url ? (
                  <a href={guest.document[1].url} target="_blank" rel="noopener noreferrer">
                    View Training Form 2
                  </a>
                ) : (
                  "N/A"
                )}
              </td>
              <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                {guest.passport[0]?.url ? (
                  <a href={guest.passport[0].url} target="_blank" rel="noopener noreferrer">
                    View Document 1
                  </a>
                ) : (
                  "N/A"
                )}
              </td>
              <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                {guest.passport[1]?.url ? (
                  <a href={guest.passport[1].url} target="_blank" rel="noopener noreferrer">
                    View Document 2
                  </a>
                ) : (
                  "N/A"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelTrainingForm;
