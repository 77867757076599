import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Authentication/login";

import Profile from "./components/profile/profile";
import toast, { Toaster } from "react-hot-toast";
import { loadUser } from "./redux/action/useravtion";
import UserDetails from "./components/singleUser/SingleUserDetails";
import Register from "./components/Register";
import AddDirectors from "./components/Directors/AddDirectors";
import FirstAddDirectors from "./components/FirstAddDirectors";
import FirstNomineeAdd from "./components/FirstNomineeAdd";
import UploadDocuments from "./components/UploadDocuments";
import AddContract from "./components/AddContract";
import AddPayment from "./components/AddPayment";
import AddSpvv from "./components/AddSpvv";
import AddSpvDocument from "./components/AddSpvDocument";
import Companylogin from "./components/CompanyAuthentication/Companylogin";
import UserDashboard from "./components/Dashboard/UserDashboard";
import Direcotr from "./components/UserDirector/Direcotr";
import Nominees from "./components/UserNominees/Nominees";
import ClientEvents from "./components/Events/ClienrEvents";
import HelpDesk from "./components/HelpDesk/HelpDesk";
import Otp from "./components/otp/Otp";
import AuthenticateOtp from "./components/otp/AuthenticateOtp";
import AdminDashboard from "./components/Admin/AdminDashboard";
import Event from "./components/Events/Event";
import Help from "./components/Events/Help";
import Notification from "./components/Notificartion/Notification";
import Guest from "./components/Guest/Guest";
import SpvNominees from "./components/UserNominees/SpvNominees";
import SpvDirectors from "./components/UserDirector/SpvDirector";
import SpvDocuments from "./components/UserDocuments/Documents";
import Contract from "./components/UserContract/Contract";
import FirstAddSpvDirectors from "./components/FirstAddSpvDirector";
import FirstSpvNomineeAdd from "./components/SpvFirstNomineeAdd";
import AdminGuest from "./components/Guest/AdminGuest";
import AllUser from "./components/AdminUser/Alluser";
import RegisterUser from "./components/Authentication/Register";
import Faq from "./components/FAQ/Faq";
import News from "./components/News/News";
import Deposit from "./components/Deposit/Deposit";
import Temp from "./components/Temp";
import GeneralHelpdesk from "./components/HelpDesk/GeneralHelpdesk";
import BankForm from "./components/Banking/BankForm";
import DirectorUpdates from "./components/Updates/DirectorUpdates";
import NomieeUpdate from "./components/Updates/UpdateNominee";
import SpvDirectorUpdates from "./components/Updates/SpvDirector";
import UpdateSPVDocument from "./components/Updates/SpvOrder";
import SpvOrder from "./components/Updates/SpvOrder";
import Refund from "./components/Banking/Refund";
import UpdateDocument from "./components/Updates/ParentDocumentUpdate/Document";
import Individualbank from "./components/IndividualBankForm/Individualbank";
import IndividualRegister from "./components/IndividualRegister/IndividualRegister";
import IndividualAddDocument from "./components/IndividualRegister/IndividualDocument";
import FirstIndividualNomineeAdd from "./components/IndividualRegister/IndividualNominee";
import AddIndividualContract from "./components/IndividualRegister/IndividualContract";
import IndividualLogin from "./components/IndividualLogin/IndividualLogin";
import AllIndividuals from "./components/AdminUser/AllIndividuals";
import TrainingForm from "./components/TrainingForm/TrainingForm";
import EditIndividual from "./components/EditIndividiual/EditIndividual";
import TrainingForm1 from "./components/TrainingForm1/TrainingForm1";
import Agreement from "./components/TrainingForm/Agreement";
import Forgetpassword from "./components/ForgetPassword/Forgetpassword";
import QualifivationForm from "./components/ChildrenForm/QualifivationForm";
import RegisteredNda from "./components/NDA/RegisteredNda";
import Nda from "./components/NDA/Nda";
import NdaDetails from "./components/NDA/NdaDetails";
import Charity from "./components/Charity/Charity";
import CreateCharity from "./components/Charity/CreateCharity";
import ViewCharity from "./components/Charity/ViewCharity";
import UnderMaintenance from "./components/UnderMaintanance/UnderMaintenance";
import AdminOtp from "./components/Authentication/Adminotp";
import AdminAuthenticateOtp from "./components/Authentication/AdminOtpAuthenication";
import LoginHistory from "./components/LoginHistory/LoginHistory";
import RegisterStaff from "./components/StaffRegistration/Register";
import Stafflist from "./components/Staff/Stafflist";
import ActivityLog from "./components/ActivityLog/ActivityLog";
import EmdReturned from "./components/EmdReturned/EmdReturn";
import Bankforms from "./components/BankForms/Bankforms";
import GetTraining from "./components/ClientTraining/GetTraining";
import TrainingModule from "./components/TrainingModule/TrainingModule";
import TrainingcreateModule from "./components/TrainingModule/TrainingcreateModule";
import ClientTraining from "./components/ClientTraining/ClientTraining";
import Attendance from "./components/Attendance/Attendance";
import ExcelBank from "./components/Excel/ExcelBank";
import ExcelTrainingForm from "./components/Excel/ExcelTrainingForm";
import ReceiptBuilder from "./components/Reciept/RecieptBuilder";


const App = () => {
  const { isAuthenticated, user, message, error } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  // const isTokenValid = () => {
  //   const token = localStorage.getItem("companyToken");
  //   if (!token) {
  //     return false;
  //   }
  //   try {
  //     const decodedToken = jwtDecode(token);
  //     const currentTime = Date.now() / 1000; // Get current time in seconds
  //     return decodedToken.exp > currentTime; // Check if token expiration is greater than the current time
  //   } catch (error) {
  //     return false; // In case of decoding error or invalid token
  //   }
  // };
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
    if (message) {
      toast.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message]);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Companylogin />} />

        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/sendotp" element={<AdminOtp />} />
        <Route path="/Authenticate/email" element={<AuthenticateOtp />} />
        <Route
          path="/adminAuthenticate/email"
          element={<AdminAuthenticateOtp />}
        />
        <Route path="/RegisterUser" element={<RegisterStaff />} />
        <Route path="/userLogin" element={<Companylogin />} />
        <Route path="/ForgetPassword" element={<Forgetpassword />} />
        <Route
          path="/admin/profile"
          element={
            isAuthenticated ? <Profile /> : <Navigate to="/admin/login" />
          }
        />
        <Route
          path="/admin/stafflist"
          element={
            isAuthenticated ? <Stafflist /> : <Navigate to="/admin/login" />
          }
        />
        <Route path="/sendotp" element={<Otp />} />
        <Route path="/Authenticate/email" element={<AuthenticateOtp />} />
        <Route
          path="/userDashboard"
          element={isAuthenticated ? <UserDashboard /> : <Navigate to="/" />}
        />
        <Route
          path="/admin/Nda"
          element={isAuthenticated ? <Nda /> : <Navigate to="/admin/login" />}
        />
        <Route path="/NdaDetails/:id" element={<NdaDetails />} />
        <Route path="/adminDashboard" element={<AdminDashboard />} />
        <Route path="/Events" element={<ClientEvents />} />
        <Route
          path="/admin/events"
          element={isAuthenticated ? <Event /> : <Navigate to="/userLogin" />}
        />
        <Route path="/admin/users" element={<AllUser />} />
        <Route path="/admin/individullist" element={<AllIndividuals />} />
        <Route path="/admin/guest/:id" element={<AdminGuest />} />
        <Route path="/helpdesk" element={<HelpDesk />} />
        <Route
          path="/admin/notification"
          element={
            isAuthenticated ? <Notification /> : <Navigate to="/admin/login" />
          }
        />
        <Route path="/admin/helpdesk" element={isAuthenticated ? <Help /> : <Navigate to="/admin/login" />
} />
        <Route
          path="/user/SpvDocuments"
          element={
            isAuthenticated ? <SpvDocuments /> : <Navigate to="/userLogin" />
          }
        />
        <Route
          path="/user/Contract"
          element={
            isAuthenticated ? <Contract /> : <Navigate to="/userLogin" />
          }
        />
        <Route
          path="/user/Deposit"
          element={isAuthenticated ? <Deposit /> : <Navigate to="/userLogin" />}
        />
        <Route
          path="/user/director"
          element={
            isAuthenticated ? <Direcotr /> : <Navigate to="/userLogin" />
          }
        />
        <Route
          path="/user/Spvdirector"
          element={
            isAuthenticated ? <SpvDirectors /> : <Navigate to="/userLogin" />
          }
        />
        <Route
          path="/user/nominee"
          element={
            isAuthenticated ? <Nominees /> : <Navigate to="/userLogin" />
          }
        />
        <Route
          path="/user/Spvnominee"
          element={
            isAuthenticated ? <SpvNominees /> : <Navigate to="/userLogin" />
          }
        />
        <Route path="/admin/register" element={<Register />} />
        <Route
          path="/admin/registerIndividual"
          element={<IndividualRegister />}
        />
        <Route path="/user/:id" element={<UserDetails />} />
        <Route path="/addmoredirectors/:id" element={<AddDirectors />} />
        <Route
          path="/admin/IndividualDocs/:companyId"
          element={<AddDirectors />}
        />
        <Route
          path="/user/temp"
          element={isAuthenticated ? <Temp /> : <Navigate to="/userLogin" />}
        />
        <Route
          path="/addDirectors/:companyId"
          element={<FirstAddDirectors />}
        />
        <Route
          path="/addSpvDirectors/:companyId"
          element={<FirstAddSpvDirectors />}
        />
        <Route path="/admin/addspvv/:companyId" element={<AddSpvv />} />
        <Route path="/AddNominee/:companyId" element={<FirstNomineeAdd />} />
        <Route
          path="/AddSpvNominee/:companyId"
          element={<FirstSpvNomineeAdd />}
        />
        <Route
          path="/UploadSpvDocuments/:companyId"
          element={<AddSpvDocument />}
        />
        <Route
          path="/UploadDocuments/:companyId"
          element={<UploadDocuments />}
        />
        <Route path="/individual/:companyId" element={<Individualbank />} />
        <Route path="/general/helpdesk" element={<GeneralHelpdesk />} />
        <Route path="/AddContract/:companyId" element={<AddContract />} />
        <Route path="/AddPayments/:companyId" element={<AddPayment />} />
        <Route path="/addguest/:id" element={<Guest />} />
        <Route path="/admin/faq" element={<Faq />} />
        <Route
          path="/admin/news"
          element={isAuthenticated ? <News /> : <Navigate to="/admin/login" />}
        />

        {/* update */}

        <Route
          path="/directorupdate/:companyId"
          element={<DirectorUpdates />}
        />
        <Route path="/editIndividual/:id" element={<EditIndividual />} />
        <Route
          path="/spvdirectorupdate/:companyId"
          element={<SpvDirectorUpdates />}
        />
        <Route path="/nomineeupdate/:companyId" element={<NomieeUpdate />} />
        {/* <Route path="/update/:companyId" element={<Document />} /> */}
        <Route path="/addOrder/:id" element={<SpvOrder />} />
        <Route
          path="/Refund/:id"
          element={isAuthenticated ? <Refund /> : <Navigate to="/" />}
        />
        <Route path="/TrainingForm/:id" element={<TrainingForm />} />
        <Route path="/agreement/:id" element={<Agreement />} />
        <Route
          path="/GlobalCorporateBankAccount/:id"
          element={
            isAuthenticated ? <BankForm /> : <Navigate to="/userLogin" />
          }
        />

        <Route
          path="/CorporatBankAccount/:id"
          element={
            isAuthenticated ? <Individualbank /> : <Navigate to="/userLogin" />
          }
        />
        <Route path="/editCompanyDoc/:id" element={<UpdateDocument />} />
        <Route
          path="/admin/IndividualAddDocument/:id"
          element={<IndividualAddDocument />}
        />
        <Route
          path="/IndividualAddNomineeAdd/:id"
          element={<FirstIndividualNomineeAdd />}
        />
        <Route
          path="/IndividualAddContract/:id"
          element={<AddIndividualContract />}
        />
        <Route path="/individual/login" element={<IndividualLogin />} />
        <Route path="/Education/:id" element={<QualifivationForm />} />
        <Route path="/traininf" element={<TrainingForm1 />} />
        <Route path="/vpvvcharity" element={<Charity />} />
        <Route path="/admin/charites/:id" element={<ViewCharity />} />
        <Route
          path="/admin/createcharity"
          element={
            isAuthenticated ? <CreateCharity /> : <Navigate to="/admin/login" />
          }
        />

        {/* NDA..................... */}
        <Route
          path="/admin/registerNDA"
          element={
            isAuthenticated ? <RegisteredNda /> : <Navigate to="/admin/login" />
          }
        />
        {/* Login history only for admin */}
        <Route
          path="/admin/loginhistory"
          element={
            isAuthenticated ? <LoginHistory /> : <Navigate to="/admin/login" />
          }
        />
        <Route
          path="/admin/activitylog"
          element={
            isAuthenticated ? <ActivityLog /> : <Navigate to="/admin/login" />
          }
        />
        <Route
          path="/admin/EmdReturned/:id"
          element={
            <EmdReturned/>
          }
        />
        <Route
          path="/admin/bankforms"
          element={
            <Bankforms/>
          }
        />
        <Route
          path="/admin/alltrainingform"
          element={
            <GetTraining/>
          }
        />
        <Route
          path="/admin/createTrainingmodule"
          element={
            <TrainingModule/>
          }
        />
        <Route
          path="/trainingmodulecreate/:id"
          element={
            <TrainingcreateModule/>
          }
        />
        <Route
          path="/mytraining/:id"
          element={
            <ClientTraining/>
          }
        />
        <Route
          path="/admin/attendance"
          element={
            <Attendance/>
          }
        />
        <Route
          path="/admin/excelbankform"
          element={
            <ExcelBank/>
          }
        />
        <Route
          path="/admin/excelTrainingform"
          element={
            <ExcelTrainingForm/>
          }
        />
        <Route
          path="/admin/recieptbuilder"
          element={
            <ReceiptBuilder/>
          }
        />
        {/* <Route path="/IndividualAddNominee/:id" element={<IndividualAddNominee />} /> */}

        {/* <Route path="/forgetPassword" element={<Forget />} />
        <Route path="/resetPassword" element={<Reset />} /> */}
        {/* <Route
          path="/admin/addDocuments"
          element={isAuthenticated && user?.role === "admin" ? <Adddocuments /> : <Navigate to="/login" />}
        />
        <Route
          path="/admin/Users"
          element={isAuthenticated && user?.role === "admin" ? <Users /> : <Navigate to="/login" />}
        /> */}
        {/* <Route
          path="/admin/company"
          element={isAuthenticated && user?.role === "admin" ? <Company /> : <Navigate to="/login" />}
        />
       
        <Route
          path="/uploaddocument"
          element={isAuthenticated ?<UploadDoc/>:<Navigate to="/login" />}
        /> */}
      </Routes>
      <Toaster />
    </Router>
  );
};

export default App;
