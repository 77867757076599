import axios from "axios";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

const ExcelBank = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const { data } = await axios.get("http://localhost:4000/api/v1/allbank");
        setGuests(data.bank);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchGuests();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredGuests = guests.filter(
    (guest) =>
      guest.Company?.name &&
      guest.Company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredGuests.map((guest) => ({
        CompanyName: guest.Company?.name || "N/A",
        AuthorizedSignatory1: guest.AuthorizedSignatory[0]?.AuthorizedFullNAme || "N/A",
        AuthorizedSignatory2: guest.AuthorizedSignatory[1]?.AuthorizedFullNAme || "N/A",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AuthorizedSignatories");
    XLSX.writeFile(wb, "AuthorizedSignatoriesList.xlsx");
  };

  const renderRows = () => {
    return filteredGuests.map((guest, index) => (
      <tr key={guest._id}>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>{index + 1}</td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {guest.Company?.name || "N/A"}
        </td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {guest.AuthorizedSignatory[0]?.AuthorizedFullNAme || "N/A"}
        </td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {guest.AuthorizedSignatory[1]?.AuthorizedFullNAme || "N/A"}
        </td>
      </tr>
    ));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          placeholder="Search by company name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            padding: "10px",
            width: "calc(100% - 120px)",
            boxSizing: "border-box",
            borderRadius: "13px",
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            borderRadius: "13px",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Sno</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Company Name
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 1
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 2
            </th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default ExcelBank;
