import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";

const AllUser = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const { data } = await axios.get("http://localhost:4000/api/v1/getallClient");
        console.log("Fetched Data", data);

        if (data && data.client) {
          setClients(data.client); // Ensure correct data path
        } else {
          setError("No clients data found.");
        }
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredClients = clients.filter((client) =>
    (client.name || "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredClients.map((client, index) => ({
        Sno: index + 1,
        "Company Name": client.name || "N/A",
        "SPV Name": client.spvvName || "N/A",
        "Directors": client.SpvDirectors?.map((dir) => dir.DirectorName).join(", ") || "N/A",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ClientData");
    XLSX.writeFile(wb, "ClientDataList.xlsx");
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          placeholder="Search by Company Name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            padding: "10px",
            width: "calc(100% - 120px)",
            boxSizing: "border-box",
            borderRadius: "13px",
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            borderRadius: "13px",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Sno</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Company Name</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>SPV Name</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Directors</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients.map((client, index) => (
            <tr key={client._id}>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {index + 1}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {client.name || "N/A"}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {client.spvvName || "N/A"}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {client.SpvDirectors?.map((dir) => dir.DirectorName).join(", ") || "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllUser;
